var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"ma-3"},[_c('v-toolbar',{staticClass:"mb-1"},[_c('v-toolbar-title',[_vm._v("Music Juries")]),_c('v-spacer')],1),_c('v-expansion-panels',{model:{value:(_vm.selectedTerm),callback:function ($$v) {_vm.selectedTerm=$$v},expression:"selectedTerm"}},_vm._l((_vm.terms),function(ref){
var term = ref.term;
var label = ref.label;
var juries = ref.juries;
var active = ref.active;
return _c('v-expansion-panel',{key:'panel-' + label},[_c('v-expansion-panel-header',[_vm._v(_vm._s(label))]),_c('v-expansion-panel-content',[_c('v-card-text',[_c('v-list',_vm._l((juries),function(ref){
var _id = ref._id;
var start = ref.start;
var instrument = ref.instrument;
var pieces = ref.pieces;
var evaluations = ref.eval;
return _c('v-list-item',{key:_id},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Jury reserved for "+_vm._s(instrument)+" on "+_vm._s(_vm.stringFormatDate(start)))]),(pieces && pieces.length > 0)?_c('v-list-item-subtitle',_vm._l((pieces),function(ref,index){
var name = ref.name;
var composer = ref.composer;
return _c('v-chip',{key:_id + '-' + index},[_vm._v(_vm._s(name)+" - "+_vm._s(composer))])}),1):_vm._e(),(evaluations.length === 0)?_c('v-list-item-subtitle',[_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){return _vm.editRepertoire(_id, pieces)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("fal fa-pencil")]),_vm._v(" Edit Repertoire ")],1),_c('v-btn',{staticStyle:{"margin-left":"1em"},attrs:{"color":"error","outlined":""},on:{"click":function($event){return _vm.cancelJury(_id)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("fal fa-times-circle")]),_vm._v(" Cancel Jury ")],1)],1):_c('v-list-item-subtitle',[_c('v-btn',{attrs:{"outlined":"","to":'/student/music/jury/' + _id}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("fal fa-eye")]),_vm._v(" View Evaluations ")],1)],1)],1)],1)}),1),(active)?_c('select-jury-dialog',{attrs:{"has-jury":juries.length > 0,"term":term},on:{"reserved":function (ref) {
	var event = ref.event;

	return juries.push(event);
}}}):_vm._e(),_c('v-dialog',{attrs:{"persistent":"","width":"600"},model:{value:(_vm.repertoireDialog),callback:function ($$v) {_vm.repertoireDialog=$$v},expression:"repertoireDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Enter the repertoire for your jury")]),_c('v-expansion-panels',_vm._l((_vm.repertoirePieces),function(piece,index){return _c('v-expansion-panel',{key:'repertoire-' + index},[_c('v-expansion-panel-header',[_vm._v("Piece #"+_vm._s(index + 1)+_vm._s(piece.name !== '' ? ' - ' + piece.name : ''))]),_c('v-expansion-panel-content',[_c('v-card-text',[_c('v-text-field',{key:'name-' + index,attrs:{"label":"Name","outlined":""},model:{value:(_vm.repertoirePieces[index].name),callback:function ($$v) {_vm.$set(_vm.repertoirePieces[index], "name", $$v)},expression:"repertoirePieces[index].name"}}),_c('v-text-field',{key:'composer-' + index,attrs:{"label":"Composer","outlined":"","hide-details":""},model:{value:(_vm.repertoirePieces[index].composer),callback:function ($$v) {_vm.$set(_vm.repertoirePieces[index], "composer", $$v)},expression:"repertoirePieces[index].composer"}})],1),_c('v-card-actions',[(_vm.repertoirePieces.length > 3)?_c('v-btn',{attrs:{"text":"","color":"error"},on:{"click":function($event){return _vm.repertoirePieces.splice(index, 1)}}},[_vm._v("Remove Piece")]):_vm._e()],1)],1)],1)}),1),_c('v-card-text',{staticStyle:{"margin-top":"1em"}},[_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){return _vm.repertoirePieces.push({ name: '', composer: '' })}}},[_vm._v("Add Another Piece")])],1),_c('v-card-actions',[_c('v-btn',{attrs:{"disabled":!_vm.repertoireValid,"text":"","color":"success"},on:{"click":function($event){return _vm.saveRepertoire()}}},[_vm._v("Save")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.repertoireDialog = false}}},[_vm._v("Cancel")])],1)],1)],1)],1)],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }